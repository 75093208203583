import omit from 'lodash/omit';
import type { AnchorProps, ButtonProps, ButtonType } from '@/components/common/buttons/Button/types';
import type { PropsWithChildren } from 'react';

export const ButtonTag = (props: PropsWithChildren<ButtonType>) => {
  const { href, innerRef } = props;
  const htmlProps = {
    ...omit(props, ['loading', 'color', 'variant', 'icon', 'isActive', 'rightIcon', 'rounded', 'innerRef']),
    ref: innerRef,
  };

  if (href) {
    return <a {...htmlProps as AnchorProps} />;
  }

  return <button type='button' {...htmlProps as ButtonProps} />;
};
