import { cva } from 'class-variance-authority';

export const buttonClasses = cva([
  'Button',
  'focus-visible:outline-[0.6rem]',
  'focus-visible:outline',
  'inline-flex',
  'items-center',
  'justify-center',
  'gap-8',
  'text-center',
  'relative',
  'group',
  'hover:no-underline',
  'focus:no-underline',
  '[&_svg]:text-current',
  'active:border-current',
  'font-[inherit]',
], {
  variants: {
    variant: {
      primary: 'border-none font-medium focus:no-underline',
      // todo: border color
      outline: 'border border-solid',
      text: 'border-none bg-transparent',
      link: 'border-none bg-transparent underline',
    },
    color: {
      primary: '',
      gray: '',
      warning: '',
      error: '',
      success: '',
    },
    size: {
      small: 'py-10 px-14 text-sm leading-[1.6rem] [&_svg]:size-16',
      medium: 'py-[1.1rem] px-16 text-sm leading-[1.7rem] [&_svg]:size-16',
      large: 'py-[1.35rem] px-18 text-base leading-[2rem] [&_svg]:size-20',
    },
    disabled: {
      true: 'cursor-not-allowed!',
    },
    loading: {
      true: 'cursor-wait!',
    },
    isActive: {
      true: '',
    },
    rounded: {
      true: 'rounded-full',
      false: 'rounded-lg',
    },
    areChildren: {
      true: '',
    },
  },
  compoundVariants: [
    { loading: false, className: 'cursor-pointer' },
    { disabled: true, className: 'text-gray-400! text-dark-gray-400!' },
    {
      color: ['primary', 'success', 'gray'],
      className: 'focus-visible:outline-success-100 dark:focus-visible:outline-dark-success-200',
    },
    {
      color: ['warning'],
      className: 'focus-visible:outline-warning-100 dark:focus-visible:outline-dark-warning-100',
    },
    {
      color: ['error'],
      className: 'focus-visible:outline-error-100 dark:focus-visible:outline-dark-error-100',
    },
    { variant: 'primary', disabled: true, className: 'bg-gray-100! dark:bg-gray-900! dark:text-gray-700!' },
    { variant: 'primary', className: 'text-white' },
    {
      variant: 'primary',
      color: 'primary',
      isActive: false,
      className: [
        'bg-primary-500',
        'dark:bg-dark-primary-500',
        'hover:bg-primary-400',
        'hover:text-white',
        'focus:text-white',
        'dark:hover:bg-dark-primary-400',
        'active:bg-primary-600',
        'dark:active:bg-dark-primary-600',
      ],
    },
    {
      variant: 'primary',
      color: 'success',
      isActive: false,
      className: [
        'bg-success-500',
        'hover:bg-success-400',
        'dark:hover:bg-dark-success-400',
        'active:bg-success-600',
      ],
    },
    {
      variant: 'primary',
      color: 'error',
      isActive: false,
      className: [
        'bg-error-500',
        'hover:bg-error-400',
        'dark:hover:bg-dark-error-400',
        'active:bg-error-600',
        'hover:text-white',
        'visited:text-white',
      ],
    },
    {
      variant: 'primary',
      color: 'gray',
      isActive: false,
      className: [
        'bg-accent-500',
        'hover:bg-accent-400',
        'hover:text-white',
        'dark:hover:bg-dark-accent-400',
        'active:bg-accent-700',
      ],
    },
    {
      variant: 'primary',
      color: 'warning',
      isActive: false,
      className: [
        'bg-warning-500',
        'hover:bg-warning-400',
        'dark:hover:bg-dark-warning-400',
        'active:bg-warning-600',
      ],
    },
    { variant: 'primary', color: 'primary', isActive: true, className: 'bg-primary-600 dark:bg-dark-primary-600' },
    { variant: 'primary', color: 'success', isActive: true, className: 'bg-success-600 dark:bg-dark-success-600' },
    { variant: 'primary', color: 'warning', isActive: true, className: 'bg-warning-600 dark:bg-dark-warning-600' },
    { variant: 'primary', color: 'gray', isActive: true, className: 'bg-accent-700 dark:bg-dark-accent-600' },
    { variant: 'primary', color: 'error', isActive: true, className: 'bg-error-600 dark:bg-dark-error-600' },
    {
      variant: 'primary',
      isActive: false,
      disabled: true,
      className: [
        'bg-gray-100',
        'text-gray-500',
        'dark:bg-dark-gray-200',
      ],
    },
    {
      variant: 'text',
      color: 'primary',
      className: [
        'hover:text-primary-400 dark:hover:text-dark-primary-400 focus:text-primary-400 dark:focus:text-dark-primary-400',
      ],
    },
    {
      variant: 'link',
      className: [
        'hover:underline!',
      ],
    },
    {
      variant: ['outline', 'text', 'link'],
      className: [
        'bg-inherit',
        'dark:inherit',
        'focus:text-primary-600',
      ],
    },
    {
      variant: ['outline', 'text', 'link'],
      color: 'primary',
      isActive: false,
      disabled: false,
      className: [
        'text-primary-500',
        'hover:text-primary-500',
        'dark:text-dark-primary-500',
        '[&_svg]:active:text-primary-600',
        'dark-active:[&_svg]:text-dark-primary-600',
        'active:text-primary-600',
        'dark:active:text-dark-primary-600',
      ],
    },
    {
      variant: ['outline', 'text', 'link'],
      color: 'gray',
      isActive: false,
      disabled: false,
      className: [
        'text-gray-700',
        'dark:text-dark-gray-700',
        'hover:text-gray-600',
        'dark:hover:text-dark-gray-600',
        'active:text-primary-500',
        'dark:active:text-dark-primary-500',
      ],
    },
    {
      variant: ['outline', 'text', 'link'],
      color: 'success',
      isActive: false,
      disabled: false,
      className: [
        'text-success-500',
        'dark:text-dark-success-500',
        'active:text-success-600',
        'dark:active:text-dark-success-600',
      ],
    },
    {
      variant: ['outline', 'text', 'link'],
      color: 'warning',
      isActive: false,
      disabled: false,
      className: [
        'text-warning-500',
        'dark:text-dark-warning-600',
        'active:text-warning-600',
        'dark:active:text-dark-warning-500',
      ],
    },
    {
      variant: ['outline', 'text', 'link'],
      color: 'error',
      isActive: false,
      disabled: false,
      className: [
        'text-error-500',
        'dark:text-dark-error-500',
        'active:text-error-600',
        'dark:active:text-dark-error-600',
      ],
    },
    { variant: 'outline', disabled: true, className: 'bg-gray-50! dark:bg-dark-gray-200!' },
    {
      variant: 'outline',
      color: 'primary',
      isActive: true,
      className: 'text-primary-600 dark:text-dark-primary-600 border-current bg-primary-50 dark:bg-dark-primary-50',
    },
    {
      variant: 'outline',
      color: 'gray',
      isActive: true,
      className: 'text-primary-500 dark:text-dark-primary-500 border-primary-500 dark:border-dark-primary-500',
    },
    { variant: 'outline', color: 'success', isActive: true, className: 'text-success-600 dark:text-dark-success-600' },
    { variant: 'outline', color: 'warning', isActive: true, className: 'text-warning-600 dark:text-dark-warning-500' },
    { variant: 'outline', color: 'error', isActive: true, className: 'text-error-600 dark:text-dark-error-600' },
    {
      variant: 'outline',
      color: 'primary',
      isActive: false,
      className: 'hover:bg-primary-50 dark:hover:bg-dark-primary-50 border-primary-500 dark:border-dark-primary-500',
    },
    {
      variant: 'outline',
      color: 'gray',
      isActive: false,
      className: 'bg-white dark:bg-inherit hover:text-gray-700 dark:hover:text-dark-gray-700 hover:bg-gray-50 dark:hover:bg-dark-gray-200 border-gray-200 dark:border-dark-gray-200',
    },
    {
      variant: 'outline',
      color: 'success',
      isActive: false,
      className: 'hover:bg-primary-50 dark:hover:bg-dark-primary-50 border-success-500 dark:border-dark-success-500',
    },
    {
      variant: 'outline',
      color: 'warning',
      isActive: false,
      className: 'hover:bg-warning-50 dark:hover:bg-dark-warning-50 border-warning-500 dark:border-dark-warning-500',
    },
    {
      variant: 'outline',
      color: 'error',
      isActive: false,
      className: 'hover:bg-error-50 dark:hover:bg-dark-error-50 border-error-500 dark:border-dark-error-500',
    },
    {
      variant: 'outline',
      isActive: false,
      disabled: true,
      className: [
        'border-gray-200',
        'bg-gray-50',
        'dark:bg-dark-gray-100',
        'dark:border-dark-gray-200',
        'text-gray-400',
        'dark:text-dark-gray-400',
      ],
    },
    { rounded: true, areChildren: false, size: 'small', className: 'size-32' },
    { rounded: true, areChildren: false, size: 'medium', className: 'size-40' },
    { rounded: true, areChildren: false, size: 'large', className: 'size-48' },
  ],
});
