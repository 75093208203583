import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { cva } from 'class-variance-authority';
import { Icon } from '@/components/common/icons/Icon/Icon';
import type { CircularProgressProps } from '@/components/common/progress/CircularProgress/CircularProgress.types';

export const CircularProgress = ({ size = 14, color = 'primary' }: CircularProgressProps) => (
  <Icon
    icon={faSpinnerThird}
    width={size}
    spin={true}
    className={cva('CircularProgress', {
      variants: {
        color: {
          primary: 'text-primary-500',
          white: 'text-white',
          gray: 'text-gray-400 dark:text-gray-700',
        },
      },
    })({ color })}
  />
);
