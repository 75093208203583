import { ButtonTag } from '@/components/common/buttons/Button/ButtonTag';
import { CircularProgress } from '@/components/common/progress/CircularProgress/CircularProgress';
import type { ButtonType } from './types';
import { buttonClasses } from './Button.classes';
import type { PropsWithChildren } from 'react';

export const Button = (props: PropsWithChildren<ButtonType>) => {
  const {
    children,
    variant = 'primary',
    size = 'medium',
    disabled = false,
    loading = false,
    icon,
    rightIcon,
    isActive = false,
    color = 'primary',
    rounded = false,
    className = '',
  } = props;

  return (
    <ButtonTag
      {...props}
      className={buttonClasses({
        size,
        disabled,
        loading,
        isActive,
        color,
        variant,
        rounded,
        areChildren: !!children,
        className,
      })}
      disabled={disabled || loading}
    >
      {icon && !loading && icon}
      {loading && (
        <CircularProgress
          color={variant === 'primary' ? 'white' : 'primary'}
          size={16}
        />
      )}
      {children}
      {rightIcon && rightIcon}
    </ButtonTag>
  );
};
